<template>
  <div v-if="isEnabled" class="p-2 text-white" style="--bs-columns: 2; background: rgba(30,30,30, 0.8)">
    <template v-for="count in shownCounts">
      <div v-if="count.condition || count.condition === undefined"
        v-tooltip="{ content: $t(count.tooltip), placement: 'left' }" class="voter-counts-grid">
        <i class="fas" :class="`fas fa-${count.icon}`"></i>
        <TweenedCount :id="countId(count.tooltip)" :count="count.count" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import TweenedCount from "./TweenedCount.vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import type { VoterCountKey, ShownCounts } from "@/types";

export default defineComponent({
  name: "VoterCounts",
  components: { TweenedCount },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    ...mapState(usePresentationStore, ["voterCounts", "activeSlide"]),
    ballotOpen() {
      return this.activeSlide !== undefined
        && this.activeSlide.type === "Slides::BallotSlide"
        && ["open", "countdown"].includes(this.activeSlide.state);
    },
    isEnabled() {
      return this.election.voterCountsConfig
        && this.election.voterCountsConfig.enabled
        && this.shownCounts.length > 0;
    },
    shownCounts() {
      return this.election.voterCountsConfig
        && this.election.voterCountsConfig.shownCounts.map((c: ShownCounts) => this.countInfo(c));
    },
  },
  methods: {
    countInfo(key: VoterCountKey) {
      switch (key) {
        case "active_eligibles":
          return {
            icon: "person-booth",
            count: this.voterCounts.activeEligibles,
            tooltip: "js.voter_counts.active_eligibles",
            condition: this.ballotOpen,
          }
        case "active_eligibles_weight":
          return {
            icon: "weight-hanging",
            count: this.voterCounts.activeEligiblesWeight,
            tooltip: "js.voter_counts.active_eligibles_weight",
            condition: this.ballotOpen,
          }
        case "active_voters":
          return {
            icon: "eye",
            count: this.voterCounts.activeVoters,
            tooltip: "js.voter_counts.active_voters",
          }
        case "total_voters":
          return {
            icon: "users",
            count: this.voterCounts.totalVoters,
            tooltip: "js.voter_counts.total_voters",
          }
      }
    },
    countId(longkey: string) {
      return longkey.split(".").pop();
    },
  },
});
</script>

<style scoped>
.voter-counts-grid {
  display: grid;
  grid-template-columns: 2fr 2fr;
  place-items: center;
  gap: 0.5rem;
}
</style>
