<template>
  <div>
    <h5>{{$t('js.ranked_summary.seat_n', { n: seatNumber })}}</h5>
    <div class="overflow-auto bg-white mb-3">
      <table class="table" id="ranked_summary_table">
        <thead class="table-light">
          <tr>
            <th>{{ $t('js.ranked_summary.candidate') }}</th>
            <th v-for="(_round, index) in rounds" class="text-center text-nowrap" v-tooltip="rounds[index].event">
              {{ $t('js.ranked_summary.round_n', { n: index + 1 }) }}
              <i class="fas fa-info-circle text-muted"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(option, optionIndex) in sortedOptions" :key="optionIndex">
            <td class="text-nowrap">{{ option.title[$i18n.locale] }}</td>
            <td
              v-for="number in rounds.length"
              :class="`text-center text-nowrap ${isEliminated(option.reference, number - 1)} ${isElected(option.reference, number - 1)}`"
            >
              {{ count(option.reference, number - 1) }}
            </td>
          </tr>
          <tr>
            <td class="text-nowrap">{{ $t('js.ranked_summary.transfered_votes') }}</td>
            <td v-for="number in rounds.length" class="text-center">
              {{ rounds[number - 1].transferred }}
            </td>
          </tr>
          <tr>
            <td class="text-nowrap">{{ $t('js.ranked_summary.exhausted') }}</td>
            <td v-for="number in rounds.length" class="text-center text-nowrap">
              {{ rounds[number - 1].exhausted }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="votesNotIncluded" class="mx-2 text-dark mb-1"><strong>
          {{ $t('js.ranked_summary.not_included_count') }}
        </strong>
        {{ votesNotIncluded }}
      </p>
      <p class="mx-2 text-dark mb-1"><strong>
          {{ $t('js.ranked_summary.abstain') }}
        </strong>
        {{ result.exclusiveOptionCounts.blank }}
      </p>
      <p class="mx-2 text-dark mb-1"><strong>
        {{ $t('js.ranked_summary.total_votes') }}
        </strong>
        {{ result.totalVotes }}
      </p>
      <p class="mx-2 text-dark"><strong>
        {{ $t('js.ranked_summary.quota') }}
        </strong>
        {{ result.quota }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import type { ResultRound, ConferenceOption, PropType, Result, ConferenceContest, Theme, ResultMethod } from "@/types";

export default defineComponent({
  name: "InstantRunoffSummary",
  props: {
    result: {
      type: Object as PropType<Result>,
      required: true,
    },
    contest: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
    theme: {
      type: String as PropType<Theme>,
        default: "light",
    },
    themeColor: {
      type: String,
      default: "",
    },
    resultMethod: {
      type: String as PropType<ResultMethod>,
      required: true,
    },
    seatNumber: {
      type: Number,
      default: null,
    },
    votesNotIncluded: {
      type: Number,
      default: null,
    },
  },
  computed: {
    rounds() {
      return this.result.rounds;
    },
    options() {
      return this.flattenOptions(this.contest.options);
    },
    sortedOptions() {
      const sortedOptions = [];
      let elected = {};

      this.rounds.map((round: ResultRound) => {
        this.options.map((option: ConferenceOption) => {
          if (round.eliminated === option.reference) sortedOptions.unshift(option);
          if (round.elected === option.reference) elected = option;
        });
      });

      // Winner on top no matter in which round was elected
      sortedOptions.unshift(elected);

      return sortedOptions;
    },
    count() {
      return (reference: string, roundIndex: number) =>
        this.rounds[roundIndex].counts[reference] || 0;
    },
    isEliminated() {
      return (reference: string, roundIndex: number) =>
        this.rounds[roundIndex].eliminated === reference && "bg-danger-faded";
    },
    isElected() {
      return (reference: string, roundIndex: number) =>
        this.rounds[roundIndex].elected === reference && "bg-success-faded";
    },
  },
  methods: {
    flattenOptions,
  },
});
</script>
