<template>
  <div v-if="progress" class="sub-lead mb-0">
    <ol
      :class="`${isRtl ? 'result-progress-inverted flex-row-reverse justify-content-end' : 'result-progress flex-row justify-content-start'} breadcrumb rounded-0 bg-transparent p-0 mb-0 d-flex`">
      <li v-for="state in possibleStates" class="breadcrumb-item text-contrast" :key="state"
        :class="{ 'active fw-bold': stateCurrent(state) }">
        {{ $t(`js.result_progress.states.${state}`) }}
        <IndeterminateSpinner v-if="stateCurrent(state) && progress !== 'finished'" />
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType, WorkflowState } from "@/types";
import IndeterminateSpinner from "@/components/backend/live/IndeterminateSpinner.vue";

export default defineComponent({
  name: "ResultProgress",
  components: { IndeterminateSpinner },
  props: {
    progress: {
      type: String as PropType<WorkflowState>,
      required: true,
    },
  },
  data() {
    return {
      possibleStates: ["anonymising", "decrypting", "aggregating", "finished"] satisfies WorkflowState[],
      isRtl: false,
    }
  },
  methods: {
    stateCurrent(state: WorkflowState) {
      if (/extracting|mixing/.test(this.progress)) {
        return state === "anonymising";
      }
      return state === this.progress;
    },
  },
  watch: {
    "$i18n.locale"() {
      this.isRtl = document.getElementsByTagName("html")[0].dir === "rtl";
    },
  },
  mounted() {
    this.isRtl = document.getElementsByTagName("html")[0].dir === "rtl";
  },
});
</script>
