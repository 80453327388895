<template>
  <div>
    <div class="overflow-auto bg-white">
      <table class="table" id="ranked_summary_table">
        <thead class="table-light">
          <tr>
            <th>{{ $t('js.ranked_summary.position') }}</th>
            <th>{{ $t('js.ranked_summary.candidate') }}</th>
            <th class="text-center" v-for="(_round, index) in rounds">
              {{ $t('js.ranked_summary.round_n', { n: index + 1 }) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :class="{ 'table-success': isElected(option), 'table-warning': isTied(option) }"
            v-for="(option) in sortedOptions">
            <td>{{ optionPosition(option) }}</td>
            <td>{{ option.title[$i18n.locale] }}</td>
            <td class="text-center" :class="{ 'font-weight-bold': number - 1 === finalRound(option.reference) }"
              v-for="number in rounds.length">
              {{ countForRound(option.reference, number - 1) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <p>{{ $t('js.ranked_summary.seats') }}: {{ contest.seats }}</p>
      <p>{{ $t('js.ranked_summary.distribution_no') }}: {{ distributionNo }}</p>
      <p v-if="elected.length > 0">
        {{ $t('js.ranked_summary.elected') }}: 
        <span>
          {{ elected.map(option => optionLabel(option)).join(', ') }}
        </span>
      </p>
      <p v-if="tied.length > 0">
        {{ $t('js.ranked_summary.tied') }}: 
        <span>
          {{ tied.map((option: string) => optionLabel(option)).join(', ') }}
        </span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Option from "../frontend/shared/Option.vue";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import type { ConferenceContest, PropType, Result, ConferenceOption, ConferenceVotingRound } from "@/types";

export default defineComponent({
  name: "RankedSummary",
  components: { Option },
  props: {
    result: {
      type: Object as PropType<Result>,
      required: true,
    },
    contest: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
  },
  computed: {
    rounds() {
      return this.result.resultData.rounds;
    },
    elected() {
      return this.result.resultData.elected;
    },
    tied() {
      return this.result.resultData.tied;
    },
    distributionNo() {
      return this.result.resultData.distributionNo;
    },
    options() {
      return this.flattenOptions(this.contest.options);
    },
    sortedOptions() {
      return [...this.options].sort(this.compareOptions);
    },
  },
  methods: {
    flattenOptions,
    optionLabel(optionReference: string) {
      return this.options.filter((option: ConferenceOption) =>
        option.reference === optionReference)[0].title[this.$i18n.locale];
    },
    finalRound(reference: string) {
      const electedIndex = this.rounds.findIndex((round: ConferenceVotingRound) =>
        round.elected.includes(reference));
      const lastIndex = this.rounds.length - 1;
      return electedIndex !== -1 ? electedIndex : lastIndex;
    },
    countForRound(reference: string, roundIndex: number) {
      if (this.finalRound(reference) >= roundIndex || this.finalRound(reference) === -1)
        return this.rounds[roundIndex].accumulatedCounts[reference];
      else
        return "";
    },
    optionPosition(option: ConferenceOption) {
      if (this.elected.includes(option.reference))
        return this.sortedOptions.indexOf(option) + 1;
      else
        return "-";
    },
    isElected(option: ConferenceOption) {
      return this.elected.includes(option.reference);
    },
    isTied(option: ConferenceOption) {
      if (this.isElected(option)) return false;
      return this.tied.includes(option.reference);
    },
    compareOptions(option1: ConferenceOption, option2: ConferenceOption) {
      const relevantRound = Math.min(this.finalRound(option1.reference), this.finalRound(option2.reference));
      const sorting = this.countForRound(option1.reference, relevantRound) - this.countForRound(option2.reference, relevantRound);

      // If two options have the same sorting score and they aren't both elected,
      // sort the elected option before the unelected option
      if (sorting === 0 && this.isElected(option1) !== this.isElected(option2))
        return this.isElected(option1) ? -1 : 1;
      else
        return -1 * sorting; // Multiplied by -1 to sort in descending order
    },
  },
});
</script>
